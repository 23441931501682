<template>
  <div :id="id" class="holiday-card card">
    <div class="card__item card__icon">
      <img :src="src" alt="" />
    </div>
    <div class="card__item card__text">
      <div class="card__text-header">
        {{ item }}
      </div>
      <div class="card__text-title">
        {{ title }}
      </div>
      <div class="card__text-description">
        {{ description }}
      </div>
      <div class="card__text-buttons">
        <ui-button
          class="mortgage-table__footer-button"
          data-popup-with-chats
          :data-popup-with-chats-title="btnForm"
        >
          {{ btnForm }}
        </ui-button>
        <ui-button outline @click="click">
          {{ btnVideo }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UIButton";

export default {
  name: "CardHoliday",
  components: {
    UiButton,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    item: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    // video: {
    //   type: String,
    //   default: ''
    // }
  },
  computed: {
    btnForm() {
      if (window.innerWidth < 768) {
        return "подробнее";
      } else {
        return "узнать подробнее";
      }
    },
    btnVideo() {
      if (window.innerWidth < 768) {
        return "видео";
      } else {
        return "Смотреть видео";
      }
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-wrap: wrap;
  background: #f9f9f9;
  box-shadow: 4px 5px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding: 8px 20px 24px 20px;

  @media screen and (min-width: $screen-sm) {
    padding: 49px 0px 56px 0px;
    margin-bottom: 55px;
  }

  &:nth-child(even) {
    .card {
      &__icon {
        @media screen and (min-width: $screen-sm) {
          order: 1;
        }
      }
      &__text {
        @media screen and (min-width: $screen-sm) {
          padding: 0 0 0 53px;
        }
      }
    }
  }

  &__item {
    width: 100%;

    @media screen and (min-width: $screen-sm) {
      width: 50%;
    }
  }

  &__icon {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $screen-sm) {
      margin-bottom: 0;
    }

    img {
      width: 200px;

      @media screen and (min-width: $screen-sm) {
        width: 360px;
      }
    }
  }

  &__text {
    box-sizing: border-box;

    @media screen and (min-width: $screen-sm) {
      padding-right: 60px;
    }

    @media screen and (min-width: 1100px) {
      padding-right: 150px;
    }

    &-header {
      color: #e14313;
      font-size: 14px;
      margin-bottom: 8px;

      @media screen and (min-width: $screen-sm) {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }

    &-title {
      font-size: 25px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #18181b;
      margin-bottom: 10px;

      @media screen and (min-width: $screen-sm) {
        font-size: 36px;
        margin-bottom: 19px;
      }
    }

    &-description {
      font-weight: 300;
      color: #5c5c5d;
      font-size: 12px;
      letter-spacing: 0.03em;
      font-family: "Rubik", sans-serif;
      margin-bottom: 25px;

      @media screen and (min-width: $screen-sm) {
        font-size: 14px;
        margin-bottom: 65px;
        line-height: 170%;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;

      @media screen and (min-width: $screen-xs) {
        justify-content: start;
      }

      .ui-button {
        padding: 12px 35px;
        font-size: 12px;

        @media screen and (min-width: $screen-xs) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>

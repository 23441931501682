<template>
  <div class="holidays-page">
    <section class="header">
      <content-wrapper-block>
        <bread-crumbs />
        <h1
          class="header__title"
          v-html="'КАНИКУЛЫ НА&nbsp;ТВОЕЙ&nbsp;УЛИЦЕ'"
        />
        <h2
          class="header__subtitle"
          v-html="'Когда вся ипотека откладывается&nbsp;на&nbsp;потом'"
        />
        <div class="header__tabs" />
      </content-wrapper-block>
    </section>

    <section v-if="holidays" class="info-mortgage">
      <content-wrapper-block>
        <div class="nav__page">
          <div class="nav__page-items item">
            <a
              v-for="(anchor, index) in holidays.blocks"
              :key="index"
              class="item__anchor"
              @click="goToHash('#' + anchor.slug)"
            >
              <span v-if="!tabsName">{{ anchor.anchor_name_pc }}</span>
              <span v-if="tabsName">{{ anchor.anchor_name_mobile }}</span>
            </a>
          </div>
        </div>
        <div class="info-mortgage__items info">
          <div class="info__item">
            <div class="info__item-title">20 <span>%</span></div>
            <div class="info__item-subtitle">Первоначальный взнос</div>
          </div>
          <span class="info__item-separator" />
          <div class="info__item">
            <div class="info__item-title">30 <span>лет</span></div>
            <div class="info__item-subtitle">Срок кредитования</div>
          </div>
          <span class="info__item-separator" />
          <div class="info__item">
            <div class="info__item-title">8 <span>%</span></div>
            <div class="info__item-subtitle">Процентная ставка</div>
          </div>
          <span class="info__item-separator" />
          <div class="info__item">
            <div class="info__item-title">
              <span>от</span> 5203 <span>р/мес</span>
            </div>
            <div class="info__item-subtitle">
              Мин. платеж до ввода дома в эксплуатацию
            </div>
          </div>
        </div>
      </content-wrapper-block>
    </section>

    <main>
      <content-wrapper-block v-if="holidays">
        <CardHoliday
          v-for="(card, index) in holidays.blocks"
          :id="card.slug"
          :key="index"
          :src="card.image.source"
          :item="card.subtitle"
          :title="card.title"
          :description="card.description"
          @click="click(card.slug)"
        />
      </content-wrapper-block>
    </main>

    <callback-block id="callback" id-formblock="question-block__callback" />

    <div v-if="isVideo" class="modal-video">
      <div class="modal-video__overlay" @click="closeModal" />
      <div class="modal-video__wrapper">
        <div class="modal-video__box">
          <span class="gg-close" @click="closeModal" />
          <video :src="linkVideo" controls autoplay preload="auto" />
        </div>
      </div>
      <!-- <span @click="closeModal" class="gg-close"></span>
      <div class="modal-video__overlay"></div>
      <div class="modal-video__block">
        <video :src='linkVideo' style="max-width: 95%; width: auto; max-height: 95%; height: auto;" controls autoplay preload='auto'></video>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import smoothscroll from "smoothscroll-polyfill";
import BreadCrumbs from "@/components/elements/BreadCrumbs";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import CardHoliday from "@/components/elements/CardHoliday";

export default {
  name: "HolidaysPage",
  components: {
    BreadCrumbs,
    ContentWrapperBlock,
    CallbackBlock,
    CardHoliday,
  },
  data: () => {
    return {
      isVideo: false,
      linkVideo: "",
      isMobile: Boolean,
    };
  },
  created() {
    // this.goToHash(this.$route.hash)
    // console.log(this.$route.hash)
  },
  computed: {
    ...mapState({
      storage: (state) => state.storage,
      // minPercent: state => state.minPercent
      // apartments: state => state.apartments
    }),
    mortgage() {
      return this.storage.mortgage;
    },
    holidays() {
      return this.storage.mortgage_landing_page;
    },
    // minPrice () {
    //   const minAmount = this.apartments.length > 0 ? parseInt(this.apartments[0].amount) : 15577000
    //   const amount = minAmount * (this.mortgage.min_mortgage_bank_pay * 0.1)
    //   return parseInt(((amount / this.mortgage.max_mortgage_bank_time) * (this.mortgage.min_mortgage_percent * 0.05)) / 12).toLocaleString('ru')
    // },
    tabsName() {
      if (window.innerWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    smoothscroll.polyfill();
    window.addEventListener("load", () => {
      if (this.$route.hash) {
        const offset =
          document.querySelector(this.$route.hash).getBoundingClientRect().top +
          window.scrollY;
        window.scrollTo({ top: offset - 80, behavior: "smooth" });
      }
    });
  },
  methods: {
    goToHash(e) {
      if (document.querySelector(e) !== null) {
        const offset =
          document.querySelector(e).getBoundingClientRect().top +
          window.scrollY;
        window.scrollTo({ top: offset - 80, behavior: "smooth" });
      }
    },
    click(e) {
      switch (e) {
        case "sells":
          this.linkVideo = require("@/assets/video1.mp4");
          break;
        case "investment":
          this.linkVideo = require("@/assets/video2.mp4");
          break;
        case "mortgage":
          this.linkVideo = require("@/assets/video3.mp4");
          break;
        case "rent":
          this.linkVideo = require("@/assets/video4.mp4");
          break;
      }
      this.isVideo = true;
      document.body.style = "overflow: hidden;";
    },
    closeModal() {
      this.isVideo = false;
      document.body.style = "overflow: visible;";
    },
  },
};
</script>

<style lang="scss" scoped>
.holidays-page {
  .modal-video {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 11111;

    &__overlay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000;
      opacity: 0.4;
      z-index: 1111;
    }

    &__wrapper {
      z-index: 2000;
      width: 1000px;
      max-width: 95%;
    }

    &__box {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;

      .gg-close {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        transform: scale(var(--ggs, 1));
        width: 40px;
        height: 40px;
        border: 2px solid transparent;
        border-radius: 40px;
        z-index: 11111111111;
        cursor: pointer;
      }

      .gg-close::after,
      .gg-close::before {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 35px;
        height: 2px;
        background: #fff;
        transform: rotate(45deg);
        border-radius: 5px;
        top: 18px;
        left: 0;
      }

      .gg-close::after {
        transform: rotate(-45deg);
      }

      & > video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav__page {
    position: absolute;
    top: -37px;
    @media screen and (max-width: $screen-xs) {
      left: 10px;
    }

    @media screen and (min-width: $screen-sm) {
      top: -39px;
    }

    &-items {
      display: flex;
    }

    &-items,
    .item {
      padding: 0;
      background: #ffffff;
      width: max-content;

      &__anchor {
        position: relative;
        font-size: 10px;
        list-style-type: none;
        padding: 13px 15px;
        letter-spacing: 0.18em;
        text-transform: uppercase;
        cursor: pointer;
        color: #18181b;
        text-decoration: none;

        @media screen and (min-width: $screen-sm) {
          font-size: 14px;
        }

        &:hover {
          @media screen and (min-width: $screen-sm) {
            color: #e14313;

            &:before {
              display: block;
              content: " ";
              position: absolute;
              height: 3px;
              width: 100%;
              background: #e14413;
              bottom: 0;
              left: 0;
              transition: all 300ms ease-out;
            }
          }
        }
      }
    }
  }

  .header {
    letter-spacing: 0.05em;
    color: #ffffff;
    background: #231f20;
    font-family: $font-family-bebas;
    padding-bottom: 50px;

    @media screen and (min-width: $screen-md) {
      padding-bottom: 80px;
    }

    &__title {
      font-size: 34px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 15px 0px 20px 0px;
      line-height: 1.2;

      @media screen and (min-width: $screen-lg) {
        font-size: 96px;
        margin: 45px 0 30px 0px;
      }
    }

    &__subtitle {
      font-size: 22px;
      font-weight: 300;
      text-transform: uppercase;

      @media screen and (min-width: $screen-lg) {
        font-size: 48px;
      }
    }
  }

  .info-mortgage {
    position: relative;
    background: #f9f9f9;

    @media screen and (min-width: $screen-sm) {
      margin-bottom: 40px;
    }

    &__items,
    .info {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 25px;

      @media screen and (min-width: $screen-xs) {
        text-align: center;
      }

      @media screen and (min-width: $screen-md) {
        flex-wrap: nowrap;
        text-align: left;
      }

      &__item {
        width: 48%;
        margin-bottom: 43px;

        @media screen and (min-width: $screen-md) {
          width: max-content;
        }

        &-separator {
          display: none;
          position: relative;

          @media screen and (min-width: $screen-md) {
            display: block;
          }

          &::after {
            content: " ";
            display: block;
            height: 72px;
            width: 0px;
            transform: rotate(45deg);
            border-left: 1px solid #cccccc;
            position: absolute;
            top: 15px;
            background-color: #000000;
            z-index: 1;
          }
        }

        &-title {
          font-size: 32px;
          letter-spacing: 0.03em;

          @media screen and (min-width: $screen-sm) {
            font-size: 80px;
          }

          span {
            font-size: 21px;
            margin-left: -2px;

            @media screen and (min-width: $screen-sm) {
              font-size: 28px;
              margin-left: -10px;
            }
          }
        }

        &-subtitle {
          color: #4b4b4b;
          font-size: 8px;
          letter-spacing: 0.18em;
          font-family: "Rubik", sans-serif;
          text-transform: uppercase;
          line-height: 1.4;
          margin-top: 7px;
        }
      }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "holidays-page" },
    [
      _c(
        "section",
        { staticClass: "header" },
        [
          _c(
            "content-wrapper-block",
            [
              _c("bread-crumbs"),
              _c("h1", {
                staticClass: "header__title",
                domProps: {
                  innerHTML: _vm._s("КАНИКУЛЫ НА&nbsp;ТВОЕЙ&nbsp;УЛИЦЕ"),
                },
              }),
              _c("h2", {
                staticClass: "header__subtitle",
                domProps: {
                  innerHTML: _vm._s(
                    "Когда вся ипотека откладывается&nbsp;на&nbsp;потом"
                  ),
                },
              }),
              _c("div", { staticClass: "header__tabs" }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.holidays
        ? _c(
            "section",
            { staticClass: "info-mortgage" },
            [
              _c("content-wrapper-block", [
                _c("div", { staticClass: "nav__page" }, [
                  _c(
                    "div",
                    { staticClass: "nav__page-items item" },
                    _vm._l(_vm.holidays.blocks, function (anchor, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          staticClass: "item__anchor",
                          on: {
                            click: function ($event) {
                              return _vm.goToHash("#" + anchor.slug)
                            },
                          },
                        },
                        [
                          !_vm.tabsName
                            ? _c("span", [
                                _vm._v(_vm._s(anchor.anchor_name_pc)),
                              ])
                            : _vm._e(),
                          _vm.tabsName
                            ? _c("span", [
                                _vm._v(_vm._s(anchor.anchor_name_mobile)),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "info-mortgage__items info" }, [
                  _c("div", { staticClass: "info__item" }, [
                    _c("div", { staticClass: "info__item-title" }, [
                      _vm._v("20 "),
                      _c("span", [_vm._v("%")]),
                    ]),
                    _c("div", { staticClass: "info__item-subtitle" }, [
                      _vm._v("Первоначальный взнос"),
                    ]),
                  ]),
                  _c("span", { staticClass: "info__item-separator" }),
                  _c("div", { staticClass: "info__item" }, [
                    _c("div", { staticClass: "info__item-title" }, [
                      _vm._v("30 "),
                      _c("span", [_vm._v("лет")]),
                    ]),
                    _c("div", { staticClass: "info__item-subtitle" }, [
                      _vm._v("Срок кредитования"),
                    ]),
                  ]),
                  _c("span", { staticClass: "info__item-separator" }),
                  _c("div", { staticClass: "info__item" }, [
                    _c("div", { staticClass: "info__item-title" }, [
                      _vm._v("8 "),
                      _c("span", [_vm._v("%")]),
                    ]),
                    _c("div", { staticClass: "info__item-subtitle" }, [
                      _vm._v("Процентная ставка"),
                    ]),
                  ]),
                  _c("span", { staticClass: "info__item-separator" }),
                  _c("div", { staticClass: "info__item" }, [
                    _c("div", { staticClass: "info__item-title" }, [
                      _c("span", [_vm._v("от")]),
                      _vm._v(" 5203 "),
                      _c("span", [_vm._v("р/мес")]),
                    ]),
                    _c("div", { staticClass: "info__item-subtitle" }, [
                      _vm._v(" Мин. платеж до ввода дома в эксплуатацию "),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "main",
        [
          _vm.holidays
            ? _c(
                "content-wrapper-block",
                _vm._l(_vm.holidays.blocks, function (card, index) {
                  return _c("CardHoliday", {
                    key: index,
                    attrs: {
                      id: card.slug,
                      src: card.image.source,
                      item: card.subtitle,
                      title: card.title,
                      description: card.description,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.click(card.slug)
                      },
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
      _vm.isVideo
        ? _c("div", { staticClass: "modal-video" }, [
            _c("div", {
              staticClass: "modal-video__overlay",
              on: { click: _vm.closeModal },
            }),
            _c("div", { staticClass: "modal-video__wrapper" }, [
              _c("div", { staticClass: "modal-video__box" }, [
                _c("span", {
                  staticClass: "gg-close",
                  on: { click: _vm.closeModal },
                }),
                _c("video", {
                  attrs: {
                    src: _vm.linkVideo,
                    controls: "",
                    autoplay: "",
                    preload: "auto",
                  },
                }),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
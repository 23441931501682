var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "holiday-card card", attrs: { id: _vm.id } },
    [
      _c("div", { staticClass: "card__item card__icon" }, [
        _c("img", { attrs: { src: _vm.src, alt: "" } }),
      ]),
      _c("div", { staticClass: "card__item card__text" }, [
        _c("div", { staticClass: "card__text-header" }, [
          _vm._v(" " + _vm._s(_vm.item) + " "),
        ]),
        _c("div", { staticClass: "card__text-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c("div", { staticClass: "card__text-description" }, [
          _vm._v(" " + _vm._s(_vm.description) + " "),
        ]),
        _c(
          "div",
          { staticClass: "card__text-buttons" },
          [
            _c(
              "ui-button",
              {
                staticClass: "mortgage-table__footer-button",
                attrs: {
                  "data-popup-with-chats": "",
                  "data-popup-with-chats-title": _vm.btnForm,
                },
              },
              [_vm._v(" " + _vm._s(_vm.btnForm) + " ")]
            ),
            _c(
              "ui-button",
              { attrs: { outline: "" }, on: { click: _vm.click } },
              [_vm._v(" " + _vm._s(_vm.btnVideo) + " ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }